.navigation-bar-item {
    font: normal normal 700 15px/1.4em 'open sans',sans-serif;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-left: 10px;
    margin-right: 10px;
}

/* unvisited link */
a.navigation-bar-item:link {
    color: rgb(92 92 92);
}

/* visited link */
a.navigation-bar-item:visited {
    color: rgb(92 92 92);
}

/* mouse over link */
a.navigation-bar-item:hover {
    color: rgb(96,166,186);
}

/* selected link */
a.navigation-bar-item:active {
    color: rgb(96,166,186);
}