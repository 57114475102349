.contact-div {
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: fixed;
}

.contact-center {
    text-align: center;
    position: absolute;
    top: 20%;
    overflow-y: scroll;
    height: 80%;
}

.title {
    text-align: left;
    padding-left: 11rem;
    padding-right: 11rem;
}

.contact-content {
    padding-left: 11rem;
}

.contact-image {
    padding-top: 3rem;
    padding-left: 5rem;
    padding-right: 11rem;
}

.contact-address {
    border: 1px solid rgba(148, 146, 146, 0.849);
    padding: 3rem;
    margin: 3rem 3rem 5rem 3rem;
    border-radius: 120px;
}

.contact-address-section {
    padding-left: 8rem;
    padding-right: 8rem;
}

@media (max-width: 1500px) {
    .contact-address-section {
        padding-left: 7rem;
        padding-right: 7rem;
    }
}

@media (max-width: 1400px) {
    .contact-address-section {
        padding-left: 6rem;
        padding-right: 6rem;
    }
}

@media (max-width: 960px) {
    .title {
        padding-top: 3rem;
    }
    /* CSS that should be displayed if width is equal to or less than 522px goes here */
    .contact-content {
        padding-left: 11rem;
        padding-right: 11rem;
    }

    .contact-image {
        padding-top: 3rem;
        padding-left: 11rem;
        padding-right: 11rem;
    }
}

@media (max-width: 768px) {
    .title {
        padding-top: 3rem;
        padding-left: 6rem;
        padding-right: 6rem;
    }
    /* CSS that should be displayed if width is equal to or less than 522px goes here */
    .contact-content {
        padding-left: 6rem;
        padding-right: 6rem;
    }

    .contact-image {
        padding-top: 3rem;
        padding-left: 6rem;
        padding-right: 6rem;
    }
}

@media (max-width: 576px) {
    .title {
        padding-top: 3rem;
        padding-left: 3rem;
        padding-right: 3rem;
    }
    /* CSS that should be displayed if width is equal to or less than 522px goes here */
    .contact-content {
        padding-left: 3rem;
        padding-right: 3rem;
    }

    .contact-image {
        padding-top: 3rem;
        padding-left: 3rem;
        padding-right: 3rem;
    }

    .contact-address {
        border: 1px solid rgba(148, 146, 146, 0.849);
        padding: 2rem 2rem 2rem 2rem;
        margin: 3rem 3rem 5rem 3rem;
        border-radius: 50px;
    }

    .contact-address-section {
        padding-left: 0rem;
        padding-right: 0rem;
    }
}


