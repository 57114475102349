.service-center {
    text-align: center;
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.service-div {
    position: absolute;
    top: 55%;
    left: 60%;
    transform: translate(-20%, -55%);
}

@media (max-width: 960px) {
    /* CSS that should be displayed if width is equal to or less than 522px goes here */
    .service-div {
    height: 60%;
    width: 50%;
    position: absolute;
    top: 58%;
    left: 55%;
    transform: translate(-20%, -55%);
    overflow-y: scroll;
    padding-right: 17px;
    }
}

@media (max-width: 576px) {
    /* CSS that should be displayed if width is equal to or less than 522px goes here */
    .service-div {
    height: 53%;
    width: 70%;
    position: absolute;
    top: 43%;
    left: 55%;
    transform: translate(-50%, -30%);
    overflow-y: scroll;
    padding-right: 17px;
    }
}

@media (max-width: 433px) {
    /* CSS that should be displayed if width is equal to or less than 522px goes here */
    .service-div {
    height: 50%;
    width: 70%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -30%);
    overflow-y: scroll;
    padding-right: 17px;
    }
}
