.site-header {
    background-color: rgb(255, 255, 255, 0.6);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
    height: auto;
    width: 100%;
    position: fixed;
}

.container {
    display: flex;
    align-items: center;
}

.container-flex {
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
    margin: 1rem;
    margin-right: 8rem;
}

.container-justify-center {
    justify-content: center;
    align-items: center;
}

@media (max-width: 1150px) {
    /* CSS that should be displayed if width is equal to or less than 522px goes here */
    .container-flex {
        display: flex;
        justify-content: space-evenly;
        flex-grow: 1;
        margin: 1rem;
        margin-right: 5rem;
    }
}

@media (max-width: 1150px) {
    /* CSS that should be displayed if width is equal to or less than 522px goes here */
    .container-flex {
        margin-right: 3.5rem;
    }
}

@media (max-width: 990px) {
    /* CSS that should be displayed if width is equal to or less than 522px goes here */
    .container-flex {
        display: flex;
        justify-content: space-evenly;
        flex-grow: 1;
        margin: 15px;
    }
}
