.partner-center {
    text-align: center;
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.partner-div {
    position: absolute;
    top: 55%;
    left: 20%;
    transform: translate(-60%, -55%);
}

@media (max-width: 1400px) {
    /* CSS that should be displayed if width is equal to or less than 522px goes here */
    .partner-div {
        top: 55%;
        left: 23%;
        transform: translate(-60%, -55%);
    }
}

@media (max-width: 1200px) {
    /* CSS that should be displayed if width is equal to or less than 522px goes here */
    .partner-div {
        top: 55%;
        left: 25%;
        transform: translate(-60%, -55%);
    }
}

@media (max-width: 1100px) {
    /* CSS that should be displayed if width is equal to or less than 522px goes here */
    .partner-div {
        top: 55%;
        left: 28%;
        transform: translate(-60%, -55%);
    }
}

@media (max-width: 960px) {
    /* CSS that should be displayed if width is equal to or less than 522px goes here */
    .partner-div {
    height: 60%;
    width: 50%;
    position: absolute;
    top: 45%;
    left: 32%;
    transform: translate(-50%, -30%);
    overflow-y: scroll;
    padding-right: 17px;
    }
}

@media (max-width: 760px) {
    /* CSS that should be displayed if width is equal to or less than 522px goes here */
    .partner-div {
    height: 60%;
    width: 70%;
    position: absolute;
    top: 45%;
    left: 45%;
    transform: translate(-50%, -30%);
    overflow-y: scroll;
    padding-right: 17px;
    }
}

@media (max-width: 540px) {
    /* CSS that should be displayed if width is equal to or less than 522px goes here */
    .partner-div {
    height: 60%;
    width: 80%;
    position: absolute;
    top: 45%;
    left: 55%;
    transform: translate(-50%, -30%);
    overflow-y: scroll;
    padding-right: 17px;
    }
}

@media (max-width: 433px) {
    /* CSS that should be displayed if width is equal to or less than 522px goes here */
    .partner-div {
    height: 50%;
    width: 70%;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -30%);
    overflow-y: scroll;
    padding-right: 17px;
    }
}
