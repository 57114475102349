.footer {
    padding-top: 20px;
    padding-bottom: 20px;
    background: rgb(56,56,56);
    color: rgb(170,168,168);
    font: normal normal normal 19px/1.4em helvetica,sans-serif;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
}