.about-center {
    text-align: center;
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.about-div {
    position: absolute;
    top: 55%;
    left: 52%;
    transform: translate(-20%, -55%);
}

@media (max-width: 960px) {
    /* CSS that should be displayed if width is equal to or less than 522px goes here */
    .about-div {
    height: 50%;
    width: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -30%);
    overflow-y: scroll;
    padding-right: 17px;
    }
}

@media (max-width: 576px) {
    /* CSS that should be displayed if width is equal to or less than 522px goes here */
    .about-div {
    height: 50%;
    width: 70%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -30%);
    overflow-y: scroll;
    padding-right: 17px;
    }
}

/* .about-content {
    padding-left: 3rem;
    padding-right: 3rem;
    padding-bottom: 1rem;
}

.about-image {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 6rem;
} */
