.menu-bar-item {
    justify-content: center;
}

span.MuiButton-label {
    font: normal normal 700 15px/1.4em 'open sans',sans-serif;
    text-decoration: none;
    text-transform: none;
    width: 100%;
    height: 100%;
    align-items: flex-start;
}

button.MuiButton-text {
    padding: 0px;
    color: rgb(113,112,112);
}

button.MuiButton-text:hover {
    padding: 0px;
    color: rgb(96,166,186);
}

button.MuiButton-text:active {
    padding: 0px;
    color: rgb(96,166,186);
}

/* unvisited link */
a.navigation-bar-item:link {
    color: rgb(113,112,112);
}

/* visited link */
a.navigation-bar-item:visited {
    color: rgb(113,112,112);
}

/* mouse over link */
a.navigation-bar-item:hover {
    color: rgb(96,166,186);
}

/* selected link */
a.navigation-bar-item:active {
    color: rgb(96,166,186);
}