.navigation-bar-header {
    font: normal normal 700 22px/1.4em 'century gothic',Serif;
    text-decoration: none;
    margin: 0.5rem;
    color: rgb(92 92 92);
}

/* unvisited link */
a.navigation-bar-header:link {
    color: rgb(92 92 92);
}

/* visited link */
a.navigation-bar-header:visited {
    color: rgb(92 92 92);
}

/* mouse over link */
a.navigation-bar-header:hover {
    color: rgb(92 92 92);
    cursor: pointer;
}

/* selected link */
a.navigation-bar-header:active {
    color: rgb(92 92 92);
}

@media (max-width: 405px) {
    .navigation-bar-header-container {
        text-align: center;
    }

    .navigation-bar-header {
        font: normal normal 700 26px/1.4em 'century gothic',Serif;
        text-decoration: none;
        margin: 1rem;
    }
}