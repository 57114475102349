.home-center {
    text-align: center;
    position: fixed;
    width: 100%;
    height: 120%;
}

/* .home-video {
    object-fit: cover;
    min-width: 100%;
    min-height: 120%;
    width: auto;
    height: auto;
    overflow: hidden;
} */

.home-div {
    position: absolute;
    top: 40%;
    left: 20%;
    transform: translate(-20%, -60%);
}

@media (max-width: 725px) {
    /* CSS that should be displayed if width is equal to or less than 522px goes here */
    .home-div {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.margin-top-26 {
    margin-top: 26px;
}

.margin-bottom-26 {
    margin-bottom: 26px;
}

.margin-bottom-39 {
    margin-bottom: 39px;
}

.margin-bottom-78 {
    margin-bottom: 78px;
}